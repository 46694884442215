import React, { useRef, useEffect } from 'react';
import { NavArrowDown, Database } from 'iconoir-react';

interface RepositorySelectorProps {
  selectedRepo: string;
  onSelectRepo: (repo: string) => void;
  repositories: string[];
}

const RepositorySelector: React.FC<RepositorySelectorProps> = ({ selectedRepo, onSelectRepo, repositories }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const allRepos = repositories[0];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-between min-w-48 max-w-md rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-center min-w-0 flex-1">
            <Database className="shrink-0 mr-2 h-4 w-4" />
            <span className="truncate">{selectedRepo}</span>
          </span>
          <NavArrowDown className="shrink-0 ml-2 h-4 w-4" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 min-w-full max-w-md rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              key={allRepos}
              className={`${
                allRepos === selectedRepo ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
              } group flex items-center w-full px-3 py-2 text-sm truncate`}
              role="menuitem"
              onClick={() => {
                onSelectRepo(allRepos);
                setIsOpen(false);
              }}
            >
              {allRepos}
            </button>
            <div className="border-t border-gray-200 my-1"></div>
            {repositories.slice(1).map((repo) => (
              <button
                key={repo}
                className={`${
                  repo === selectedRepo ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                } group flex items-center w-full px-3 py-2 text-sm truncate`}
                role="menuitem"
                onClick={() => {
                  onSelectRepo(repo);
                  setIsOpen(false);
                }}
              >
                {repo}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RepositorySelector;
