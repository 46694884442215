import React, { useState } from 'react';
import { FilterList, NavArrowDown } from 'iconoir-react';

interface Category {
  label: string;
  value: string;
}

interface CategoryFilterProps {
  categories: Category[];
  selectedCategory: string | null;
  onCategoryChange: (category: string | null) => void;
  placeholder?: string;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  categories,
  selectedCategory,
  onCategoryChange,
  placeholder = "All Categories"
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (categoryValue: string | null) => {
    onCategoryChange(categoryValue);
    setIsOpen(false);
  };

  const selectedLabel = selectedCategory
    ? categories.find(cat => cat.value === selectedCategory)?.label
    : placeholder;

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-center">
            <FilterList className="mr-2 h-4 w-4" />
            <span className="truncate">{selectedLabel}</span>
          </span>
          <NavArrowDown className="ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              className={`block w-full text-left px-4 py-2 text-sm ${
                !selectedCategory
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900'
              }`}
              role="menuitem"
              onClick={() => handleSelect(null)}
            >
              <div>{placeholder}</div>
            </button>
            {categories.map((category) => (
              <button
                key={category.value}
                className={`block w-full text-left px-4 py-2 text-sm ${
                  selectedCategory === category.value
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                }`}
                role="menuitem"
                onClick={() => handleSelect(category.value)}
              >
                <div>{category.label}</div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
