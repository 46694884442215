export interface Organization {
    name: string;
    repoCount: number;
    installationId: number;
    isSelected: boolean;
  }

export interface SettingsProps {
    organisations: Organization[];
    username: string;
    email: string;
  }

export const settings: SettingsProps =
    {
        organisations: [
            {name: 'langchain-ai', repoCount: 3, installationId: 2323, isSelected: true},
            {name: 'pydantic', repoCount: 2, installationId: 3232,  isSelected: false}
        ],
        username: '@zachdotai',
        email: 'admin@langchain.com',
    };
