import React, { createContext, useState, useContext, useEffect } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  userId: string | null;
  token: string | null;
  login: (token: string) => Promise<boolean>;
  logout: () => void;
  verifyEmail: (email: string) => Promise<void>;
}

interface TokenValidationResponse {
  user_id: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const validateToken = async (token: string): Promise<TokenValidationResponse | null> => {
  try {
    const response = await fetch('https://api.maxium.ai/v1/verify/token-validation', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Verification-Token': `${token}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch {
    return null;
  }
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const validateStoredToken = async () => {
      const storedToken = localStorage.getItem('mxvt');
      if (storedToken) {
        const validation = await validateToken(storedToken);
        if (validation) {
          setIsAuthenticated(true);
          setUserId(validation.user_id);
          setToken(storedToken);
        } else {
          localStorage.removeItem('mxvt');
          setIsAuthenticated(false);
          setUserId(null);
          setToken(null);
        }
      }
    };

    validateStoredToken();
  }, []);

  const login = async (newToken: string): Promise<boolean> => {
    try {
      const validation = await validateToken(newToken);

      if (validation) {
        const oldToken = localStorage.getItem('mxvt');
        if (oldToken && oldToken !== newToken) {
          localStorage.removeItem('mxvt');
        }
        localStorage.setItem('mxvt', newToken);
        setIsAuthenticated(true);
        setUserId(validation.user_id);
        setToken(newToken);
        return true;
      }

      return false;
    } catch {
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('mxvt');
    setIsAuthenticated(false);
    setUserId(null);
    setToken(null);
  };

  const verifyEmail = async (email: string) => {
    try {
      await fetch('https://api.maxium.ai/v1/verify/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_address: email })
      });
    } catch {
      // Silently handle any errors
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, token, login, logout, verifyEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
