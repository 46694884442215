import React, { useMemo, useState } from 'react';
import { NavArrowDown, FilterList } from 'iconoir-react';

interface TimePeriod {
    label: string;
    value: string;
    dateRange: string;
}

const generateTimePeriods = (): TimePeriod[] => {
    const today = new Date();
    const formatDate = (date: Date) => date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    const getDateBefore = (days: number) => {
        const date = new Date(today);
        date.setDate(date.getDate() - days);
        return date;
    };

    return [
        {
            label: 'Last 7 days',
            value: '7d',
            dateRange: `${formatDate(getDateBefore(6))} - ${formatDate(today)}`
        },
        {
            label: 'Last 4 weeks',
            value: '4w',
            dateRange: `${formatDate(getDateBefore(27))} - ${formatDate(today)}`
        },
        {
            label: 'Last 12 weeks',
            value: '12w',
            dateRange: `${formatDate(getDateBefore(83))} - ${formatDate(today)}`
        },
        {
            label: 'Last 24 weeks',
            value: '24w',
            dateRange: `${formatDate(getDateBefore(167))} - ${formatDate(today)}`
        },
    ];
};

interface TimePeriodSelectorProps {
    onSelect: (period: TimePeriod) => void;
}

const TimePeriodSelector: React.FC<TimePeriodSelectorProps> = ({ onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const timePeriods = useMemo(() => generateTimePeriods(), []);
    const [selectedPeriod, setSelectedPeriod] = useState(timePeriods[0]);

    const handleSelect = (period: TimePeriod) => {
        setSelectedPeriod(period);
        onSelect(period);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <span className="flex items-center">
                        <FilterList className="mr-2 h-4 w-4" />
                        <span className="truncate">{selectedPeriod.label}</span>
                    </span>
                    <NavArrowDown className="ml-2 h-5 w-5" aria-hidden="true" />
                </button>
            </div>

            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {timePeriods.map((period) => (
                            <button
                                key={period.value}
                                className={`block w-full text-left px-4 py-2 text-sm ${
                                    selectedPeriod.value === period.value
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                                }`}
                                role="menuitem"
                                onClick={() => handleSelect(period)}
                            >
                                <div>{period.label}</div>
                                <div className="text-xs text-gray-500">{period.dateRange}</div>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimePeriodSelector;
