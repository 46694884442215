export interface Automation {
    title: string;
    description: string;
    icon: string;
    comingSoon: boolean;
  }

  export const automations: Automation[] = [
    {
      title: "Maxium Auto-stack",
      description: 'Use this automation to auto-stack pull requests above a certain size across your organisation. Stacked pull requests are easier to review, faster to merge, and help structure your code.',
      icon: 'auto-stack',
      comingSoon: true,
    },
    {
      title: "Maxium Documentor",
      description: 'Keeping your documentation up-to-date is needed to ensure alignment as your product grows. Worry less about it and let us create and maintain your single source of truth for code documentation.',
      icon: 'documentation',
      comingSoon: true,
    },
    {
      title: "10x Engineer Badge",
      description: 'Celebrate your top engineers on a periodic basis to encourage them to excel. Use this automation to identify your 10x engineer and give them the chance to showcase this to the world.',
      icon: '10x-engineer',
      comingSoon: true,
    },
  ];
