import React, { useState, useMemo } from 'react';
import { NavArrowDown, Xmark } from 'iconoir-react';
import { PullRequest } from '../types/pullRequest';

interface FilterState {
  authors: string[];
  reviewedBy: string[];
  taskLabels: string[];
  baseBranches: string[];
  sizesOfPR: string[];
}

interface FilterPanelProps {
  pullRequests: PullRequest[];
  onFilterChange: (filters: FilterState) => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ pullRequests, onFilterChange }) => {
  const [filters, setFilters] = useState<FilterState>({
    authors: [],
    reviewedBy: [],
    taskLabels: [],
    baseBranches: [],
    sizesOfPR: [],
  });

  const uniqueValues = useMemo(() => {
    const getUniqueValues = (key: keyof PullRequest): string[] =>
      Array.from(new Set(pullRequests.flatMap(pr => {
        const value = pr[key];
        return Array.isArray(value) ? value : [String(value)];
      }))).sort();

    return {
      authors: getUniqueValues('author'),
      reviewers: getUniqueValues('reviewedBy'),
      taskLabels: getUniqueValues('taskLabels'),
      baseBranches: getUniqueValues('baseBranch'),
      sizes: ['Small', 'Medium', 'Large', 'Epic', 'Unknown'].sort(),
    };
  }, [pullRequests]);

  const handleFilterChange = (filterName: keyof FilterState, value: string) => {
    setFilters(prevFilters => {
      let updatedFilter: string[];

      if (value === 'All') {
        // If 'All' is selected, clear all other selections
        updatedFilter = [];
      } else if (prevFilters[filterName].includes('All')) {
        // If 'All' was previously selected, remove it and add the new value
        updatedFilter = [value];
      } else if (prevFilters[filterName].includes(value)) {
        // If the value is already selected, remove it
        updatedFilter = prevFilters[filterName].filter(item => item !== value);
      } else {
        // Otherwise, add the new value
        updatedFilter = [...prevFilters[filterName], value];
      }

      const newFilters = { ...prevFilters, [filterName]: updatedFilter };
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  const renderMultiSelect = (label: string, options: string[], selectedValues: string[], onChange: (value: string) => void) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-500 mb-1">{label}</label>
      <div className="relative">
        <select
          value=""
          onChange={(e) => onChange(e.target.value)}
          className="block w-full bg-white border border-gray-300 rounded-md py-2 px-3 pr-8 leading-tight focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-gray-400"
        >
          <option value="">Select {label.toLowerCase()}</option>
          <option value="All">All</option>
          {options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap mt-2">
        {selectedValues.length === 0 && (
          <span className="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full flex items-center">
            All
            <button onClick={() => onChange('All')} className="ml-1 focus:outline-none">
              <Xmark className="h-3 w-3" />
            </button>
          </span>
        )}
        {selectedValues.map((value) => (
          <span key={value} className="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 mb-2 px-2.5 py-0.5 rounded-full flex items-center">
            {value}
            <button onClick={() => onChange(value)} className="ml-1 focus:outline-none">
              <Xmark className="h-3 w-3" />
            </button>
          </span>
        ))}
      </div>
    </div>
  );

  return (
    <div className="bg-gray-50 rounded-lg p-6 w-64">
      <h2 className="text-lg font-semibold mb-4">Filters</h2>
      {renderMultiSelect('Author', uniqueValues.authors, filters.authors, (value) => handleFilterChange('authors', value))}
      {renderMultiSelect('Reviewed by', uniqueValues.reviewers, filters.reviewedBy, (value) => handleFilterChange('reviewedBy', value))}
      {renderMultiSelect('Task Label', uniqueValues.taskLabels, filters.taskLabels, (value) => handleFilterChange('taskLabels', value))}
      {renderMultiSelect('Base Branch', uniqueValues.baseBranches, filters.baseBranches, (value) => handleFilterChange('baseBranches', value))}

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Size of PR</label>
        <div className="flex flex-wrap -mx-1">
          {uniqueValues.sizes.map((size) => (
            <button
              key={size}
              className={`m-1 px-3 py-1 text-sm rounded-md ${
                filters.sizesOfPR.includes(size)
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
              onClick={() => handleFilterChange('sizesOfPR', size)}
            >
              {size}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
