import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/Auth';

const LoginPage: React.FC = () => {
  const { login, verifyEmail } = useAuth();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const attemptLogin = async () => {
      try {
        const storedToken = localStorage.getItem('mxvt');

        if (storedToken) {
          await login(storedToken);
          return;
        }

        const params = new URLSearchParams(location.search);
        const urlToken = params.get('token');

        if (urlToken) {
          await login(urlToken);
        }
      } finally {
        setIsInitializing(false);
      }
    };

    attemptLogin();
  }, [location, login]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await verifyEmail(email);
      setIsEmailSent(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center w-96">
        <div className="mb-6">
          <img src="/maxium-icon.png" alt="Maxium Icon" className="w-16 h-16 rounded-lg mx-auto mb-4" />
          <h1 className="text-3xl font-bold mb-2">Welcome!</h1>
          {isInitializing ? (
            <div className="flex justify-center items-center h-16">
              <div className="w-8 h-8 border-2 border-gray-800 border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : (
            <>
              <p className="text-gray-600">
                {isEmailSent
                  ? "You'll receive an email with login instructions if an account exists for this email."
                  : "Get started with Maxium"
                }
              </p>
            </>
          )}
        </div>

        {!isInitializing && (
          <>
            {!isEmailSent ? (
              <>
                <div className="mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <button
                  onClick={handleLogin}
                  disabled={!isValidEmail(email) || isLoading}
                  className="w-full mb-3 bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                  ) : (
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                    </svg>
                  )}
                  {isLoading ? 'Verifying...' : 'Login with email'}
                </button>

                <a
                  href="https://github.com/apps/maxium-bot/installations/select_target"
                  className="w-full bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition duration-300 flex items-center justify-center no-underline"
                >
                  <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 0C4.477 0 0 4.477 0 10c0 4.42 2.865 8.166 6.839 9.489.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.603-3.369-1.34-3.369-1.34-.454-1.156-1.11-1.463-1.11-1.463-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.831.092-.646.35-1.086.636-1.336-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.295 2.747-1.026 2.747-1.026.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.934.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10c0-5.523-4.477-10-10-10z" clipRule="evenodd" />
                  </svg>
                  Sign up using GitHub
                </a>

                <p className="mt-4 text-xs text-gray-600">
                  By signing in, you agree to our{' '}
                  <a href="https://www.maxium.ai/terms-and-conditions" className="text-blue-600 hover:underline">Terms and Conditions</a>{' '}
                  and consent to our{' '}
                  <a href="https://www.maxium.ai/privacy-policy" className="text-blue-600 hover:underline">Privacy Policy</a>.
                </p>
              </>
            ) : (
              <p className="mt-4 text-sm text-gray-600">
                Having a problem with verification?{' '}
                <a href="mailto:founders@maxium.ai" className="text-blue-600 hover:underline">
                  Contact us
                </a>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
