import React, { useState, useEffect } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';

const SetupPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [hasClickedVerify, setHasClickedVerify] = useState(false);
  const [hasAttemptedVerification, setHasAttemptedVerification] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);

  // Check for installation_id and store it in localStorage
  useEffect(() => {
    const installationId = searchParams.get('installation_id');
    if (installationId) {
      localStorage.setItem('setupInstallationId', installationId);
      // Remove installation_id from URL
      searchParams.delete('installation_id');
      setSearchParams(searchParams);
    }

    // Check if user has previously clicked verify
    const verifyStatus = localStorage.getItem('hasVerified');
    if (verifyStatus === 'true') {
      setHasClickedVerify(true);
    }
  }, [searchParams, setSearchParams]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
    // Reset verification failed state when email changes
    setVerificationFailed(false);
  };

  const handleEmailVerification = async () => {
    setHasAttemptedVerification(true);

    if (!email) {
      setError('Please enter your email address');
      return;
    }

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    const installationId = localStorage.getItem('setupInstallationId');
    if (!installationId) {
      setVerificationFailed(true);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://api.maxium.ai/v1/verify/${installationId}/initiate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'X-API-Key': `${process.env.MAXIUM_CLIENT_KEY}`,
          },
          body: JSON.stringify({ email_address: email })
        }
      );

      if (!response.ok) {
        setVerificationFailed(true);
      }

      setHasClickedVerify(true);
      localStorage.setItem('hasVerified', 'true');
    } catch {
      setVerificationFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isEmailVerified && !isLoading && !error) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center w-96">
        <div className="mb-6">
          <img src="/maxium-icon.png" alt="Maxium Icon" className="w-16 h-16 rounded-lg mx-auto mb-4" />
          <h1 className="text-3xl font-bold mb-2">Complete Setup</h1>
          {verificationFailed ? (
            <p className="text-gray-600">
              Email verification failed due to an issue.
            </p>
          ) : !hasClickedVerify ? (
            <p className="text-gray-600">Verify your email to continue</p>
          ) : (
            <p className="text-gray-600">You should receive a verification link in your email if there is a corresponding app installation.</p>
          )}
        </div>

        {!hasClickedVerify && !verificationFailed && (
          <>
            <div className="mb-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 ${
                  !isEmailValid && hasAttemptedVerification ? 'border-red-500' : 'border-gray-300'
                }`}
              />
              {!isEmailValid && hasAttemptedVerification && (
                <p className="text-red-500 text-sm text-left mt-1">Please enter a valid email address</p>
              )}
            </div>

            <button
              onClick={handleEmailVerification}
              disabled={isLoading || !email || !isEmailValid}
              className="w-full bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
              ) : (
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
              {isLoading ? 'Verifying...' : 'Verify Email'}
            </button>

            <p className="mt-4 text-xs text-gray-600">
              By continuing, you agree to our{' '}
              <a href="https://www.maxium.ai/terms-and-conditions" className="text-blue-600 hover:underline">
                Terms and Conditions
              </a>{' '}
              and consent to our{' '}
              <a href="https://www.maxium.ai/privacy-policy" className="text-blue-600 hover:underline">
                Privacy Policy
              </a>
              .
            </p>
          </>
        )}

        {(hasClickedVerify || verificationFailed) && (
          <p className="mt-4 text-sm text-gray-600">
            Having a problem with verification?{' '}
            <a href="mailto:founders@maxium.ai" className="text-blue-600 hover:underline">
              Contact us
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default SetupPage;
