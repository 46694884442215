import React from 'react';
import { Medal1st, GitFork, GoogleDocs } from 'iconoir-react';

interface AutomationCardProps {
  title: string;
  description: string;
  icon: string;
  comingSoon: boolean;
}

const AutomationCard: React.FC<AutomationCardProps> = ({
  title,
  description,
  icon,
  comingSoon,
}) => {
  const renderIcon = () => {
    switch (icon) {
      case 'auto-stack':
        return <GitFork className="w-6 h-6" />;
      case 'documentation':
        return <GoogleDocs className="w-6 h-6" />;
      case '10x-engineer':
        return <Medal1st className="w-6 h-6" />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow p-4 flex flex-col h-full relative">
      <div className="flex justify-between items-start mb-3">
        <div
          className="w-16 h-16 rounded-lg flex items-center text-gray-500 justify-center relative overflow-hidden"
          style={{
            '--bg-opacity': '0.4',
          } as React.CSSProperties}
        >
          <div
            className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-[var(--bg-opacity)]"
            style={{ backgroundImage: "url('/automation-background.png')" }}
          ></div>
          <div className="relative z-10">{renderIcon()}</div>
        </div>
        {comingSoon && (
          <div className="bg-indigo-100 text-indigo-600 text-xs font-semibold px-2 py-1 rounded-full">
            Coming soon
          </div>
        )}
      </div>
      <div className="flex-grow">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        {description && <p className="text-sm text-gray-500 mb-4">{description}</p>}
      </div>
      <div className="flex gap-2 mt-auto">
        <button className="flex-1 bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm font-medium">
          Details
        </button>
        <button className="flex-1 bg-gray-100 text-gray-400 px-4 py-2 rounded cursor-not-allowed text-sm font-medium" disabled>
          Activate
        </button>
      </div>
    </div>
  );
};

export default AutomationCard;
