export interface SuccessMeasure {
    title: string;
    description: string;
    type: string;
    needsAttention: number;
    activeAutomations: number;
  }

  export const successMeasures: SuccessMeasure[] = [
    {
      title: "Direction",
      description: "Are your development resources directed towards the right tasks?",
      type: "direction",
      needsAttention: 8,
      activeAutomations: 0,
    },
    {
      title: "Speed",
      description: "Are you shipping code as efficiently as possible and at the right speed?",
      type: "speed",
      needsAttention: 5,
      activeAutomations: 1,
    },
    {
      title: "Health",
      description: "Are you creating the right work environment for your engineers to excel?",
      type: "health",
      needsAttention: 14,
      activeAutomations: 3
    },
  ];
