import React from 'react';
import { CheckCircle, Eye } from 'iconoir-react';

export interface ActionMenuProps {
  selectedRows: Set<number>;
  totalRows: number;
  data: Array<{
    status: string;
    // ... other fields
  }>;
  onAcknowledge: () => void;
  onMonitor: () => void;
  onAutoResolve: () => void;
}

const FloatingActionMenu = ({
  selectedRows,
  totalRows,
  data,
  onAcknowledge,
  onMonitor,
  onAutoResolve,
}: ActionMenuProps) => {
  if (selectedRows.size === 0) return null;

  // Check if any selected row has "Automation Available" status
  const hasAutomationAvailable = Array.from(selectedRows).some(
    index => data[index]?.status === "Automation Available"
  );

  return (
    <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-50">
      <div className="bg-gray-900 text-white px-6 py-2 rounded-lg shadow-lg flex items-center space-x-8">
        <div className="text-sm font-medium">
          {selectedRows.size} of {totalRows} selected
        </div>

        <div className="h-4 w-px bg-gray-700" />

        <div className="flex items-center space-x-8 h-8">
          <button
            onClick={onAcknowledge}
            className="text-sm font-medium hover:bg-gray-800 rounded-md px-3 py-1.5 transition-colors flex items-center space-x-2"
          >
            <CheckCircle className="w-4 h-4" />
            <span>Acknowledge</span>
          </button>

          <button
            onClick={onMonitor}
            className="text-sm font-medium hover:bg-gray-800 rounded-md px-3 py-1.5 transition-colors flex items-center space-x-2"
          >
            <Eye className="w-4 h-4" />
            <span>Monitor</span>
          </button>

          {hasAutomationAvailable && (
            <button
              onClick={onAutoResolve}
              className="text-sm font-medium hover:bg-gray-800 rounded-md px-3 py-1.5 transition-colors flex items-center space-x-2"
            >
              <img
                src="/maxium-icon.png"
                alt="Auto-resolve"
                className="w-4 h-4"
              />
              <span>Auto-resolve</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FloatingActionMenu;
