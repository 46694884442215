import React, { useState } from 'react';
import { NavArrowUp, NavArrowDown, InfoCircle } from 'iconoir-react';

interface TaskRowProps {
  label: string;
  color: string;
  weeks: Array<{
    percentage: number;
    change: number;
    delta: number;
  }>;
}

const getColorClass = (color: string) => {
  switch (color) {
    case 'yellow': return 'bg-yellow-400';
    case 'purple': return 'bg-purple-400';
    case 'blue': return 'bg-blue-400';
    default: return 'bg-gray-400';
  }
};

const TaskRow: React.FC<TaskRowProps> = ({ label, color, weeks }) => {
  const colorClass = getColorClass(color);

  return (
    <div className="flex items-center border-b border-gray-200 py-4">
      <div className="w-1/5 flex items-center">
        <div className={`w-3 h-3 rounded-full ${colorClass} mr-3`}></div>
        <span className="text-sm font-medium">{label}</span>
      </div>
      {weeks.map((week, index) => (
        <div key={index} className="w-1/5 px-2">
          <div className="flex items-center justify-between mb-1">
            <span className="text-2xl font-bold">{week.percentage}%</span>
            <span className="text-xs text-gray-500">
              {week.change > 0 ? '+' : ''}{week.change}% from initiatives
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className={`${colorClass} h-2 rounded-full`}
                style={{ width: `${week.percentage}%` }}
              ></div>
            </div>
            {week.delta !== 0 && (
              <span className={`ml-2 ${week.delta > 0 ? 'text-green-500' : 'text-red-500'} flex items-center`}>
                {week.delta > 0 ? <NavArrowUp/> : <NavArrowDown />}
                <span className="text-xs ml-1">{Math.abs(week.delta)}</span>
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const TaskDistributionTable: React.FC = () => {
    const [expanded, setExpanded] = useState(false);

    const tasks = [
      {
        label: 'Documentation',
        color: 'yellow',
        weeks: [
          { percentage: 22, change: 10, delta: -11 },
          { percentage: 50, change: 8, delta: 4 },
          { percentage: 8, change: 10, delta: -2 },
          { percentage: 85, change: 10, delta: -4 },
        ],
      },
      {
        label: 'Bugs',
        color: 'purple',
        weeks: [
          { percentage: 62, change: 40, delta: -40 },
          { percentage: 10, change: 2, delta: 24 },
          { percentage: 53, change: 10, delta: -12 },
          { percentage: 92, change: 40, delta: 42 },
        ],
      },
      {
        label: 'Enhancements',
        color: 'blue',
        weeks: [
          { percentage: 12, change: 14, delta: -2 },
          { percentage: 39, change: 5, delta: 7 },
          { percentage: 21, change: 14, delta: 19 },
          { percentage: 65, change: 23, delta: 7 },
        ],
      },
      // Add a fourth task to ensure the expand button shows
      {
        label: 'Testing',
        color: 'green',
        weeks: [
          { percentage: 30, change: 5, delta: 3 },
          { percentage: 45, change: 7, delta: 15 },
          { percentage: 60, change: 15, delta: 15 },
          { percentage: 75, change: 20, delta: 15 },
        ],
      },
    ];

    const visibleTasks = expanded ? tasks : tasks.slice(0, 3);

    return (
      <div className="bg-white p-4 rounded-lg border border-gray-200">
        <div className="flex mb-4 font-medium text-gray-700 border-b pb-2">
          <div className="w-1/5">Task Label</div>
          <div className="w-1/5 px-2">W-3</div>
          <div className="w-1/5 px-2">W-2</div>
          <div className="w-1/5 px-2">W-1</div>
          <div className="w-1/5 px-2">Current Week</div>
        </div>
        {visibleTasks.map((task, index) => (
          <TaskRow key={index} {...task} />
        ))}
        {tasks.length > 3 && (
          <button
            className="mt-4 flex items-center text-black-500 hover:text-black-700"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <>
                <NavArrowUp className="mr-1" />
                <span className="text-sm">Show less</span>
              </>
            ) : (
              <>
                <NavArrowDown className="mr-1" />
                <span className="text-sm">Show all {tasks.length} task types</span>
              </>
            )}
          </button>
        )}
        <div className="mt-4 flex items-center text-sm text-gray-500 italic">
            <InfoCircle className="w-4 h-4 text-gray-400 mr-1" />
            <p>Please note filters don't apply to the task distribution table above yet.</p>
        </div>
      </div>
    );
  };

  export default TaskDistributionTable;
