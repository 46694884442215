import React from 'react';
import AutomationCard from '../components/AutomationCard';
import { automations } from '../sample_data/automations';
import AutomationsTable from '../components/AutomationsTable';

const AutomationsPage: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold">Automations</h1>
      <section className="mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {automations.map((automation, index) => (
            <AutomationCard key={index} {...automation} />
          ))}
        </div>
      </section>
      <section>
        <h2 className="text-xl font-semibold mb-4">History</h2>
        <AutomationsTable automations={[]} />
      </section>
    </div>
  );
};

export default AutomationsPage;
