import React from 'react';

interface ToggleProps {
  label: string;
  isOn: boolean;
  onToggle: () => void;
}

const Toggle: React.FC<ToggleProps> = ({ label, isOn, onToggle }) => {
  return (
    <div className="flex items-center">
      <span className="mr-3 text-sm font-medium text-gray-900">{label}</span>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={isOn}
          onChange={onToggle}
        />
        <div className={`
          w-11 h-6 bg-gray-200
          peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300
          rounded-full peer
          peer-checked:after:translate-x-full peer-checked:after:border-white
          after:content-[''] after:absolute after:top-[2px] after:left-[2px]
          after:bg-white after:border-gray-300 after:border after:rounded-full
          after:h-5 after:w-5 after:transition-all
          peer-checked:bg-indigo-500 peer-checked:bg-opacity-80
        `}></div>
      </label>
    </div>
  );
};

export default Toggle;
