export interface TimeSeriesDataPoint {
    date: string;
    value: number;
  }

export interface Metric {
  title: string;
  getValue: (timePeriod: string, repository: string) => number | TimeSeriesDataPoint[];
  getChangePercentage: (timePeriod: string, repository: string) => number;
  isPercentage: boolean;
  isTimeSeries: boolean;
}

export const healthMetrics: Metric[] = [
  {
    title: "Engineers Churn Rate (last 12 months)",
    getValue: (timePeriod, repository) => {
      return 0.23;
    },
    getChangePercentage: (timePeriod, repository) => {
      return -5;
    },
    isPercentage: true,
    isTimeSeries: false,
  },
  {
    title: "Proportion of Engineers under Excessive Load",
    getValue: (timePeriod, repository) => {
      // Placeholder logic - replace with actual data fetching/calculation
      return 0.3;
    },
    getChangePercentage: (timePeriod, repository) => {
      // Placeholder logic - replace with actual data fetching/calculation
      return 40;
    },
    isPercentage: true,
    isTimeSeries: false,
  },
  {
    title: "Unsustainable Activity Rate per Engineer (median)",
    getValue: (timePeriod, repository) => {
      // Placeholder logic - replace with actual data fetching/calculation
      return 0.15;
    },
    getChangePercentage: (timePeriod, repository) => {
      // Placeholder logic - replace with actual data fetching/calculation
      return -8;
    },
    isPercentage: true,
    isTimeSeries: false,
  },
];


export const velocityMetrics: Metric[] = [
    {
      title: "PRs Merged per Engineer (median)",
      getValue: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return 11;
      },
      getChangePercentage: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return 22;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
    {
      title: "Lines Modified per Engineer (median)",
      getValue: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return 937;
      },
      getChangePercentage: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return 18.7;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
    {
      title: "Total Output per Engineer (median)",
      getValue: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return 61.9;
      },
      getChangePercentage: (timePeriod, repository) => {
        // Placeholder logic - replace with actual data fetching/calculation
        return -3.6;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
    {
        title: "Hours to First Review (median)",
        getValue: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return 8.3;
        },
        getChangePercentage: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return 7.9;
        },
        isPercentage: false,
        isTimeSeries: false,
      },
      {
        title: "Hours from First Commit to Merge (median)",
        getValue: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return 47;
        },
        getChangePercentage: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return -14.4;
        },
        isPercentage: false,
        isTimeSeries: false,
      },
      {
        title: "Total Output per Engineer (trend)",
        getValue: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return [
            { date: '2024-01', value: 100 },
            { date: '2024-02', value: 120 },
            { date: '2024-03', value: 110 },
            { date: '2024-04', value: 130 },
            { date: '2024-05', value: 150 },
          ];
        },
        getChangePercentage: (timePeriod, repository) => {
          // Placeholder logic - replace with actual data fetching/calculation
          return -3.6;
        },
        isPercentage: false,
        isTimeSeries: true,
      },
  ];


  export const signalsMetrics: Metric[] = [
    {
      title: "Active Risks",
      getValue: (timePeriod, repository) => {
        return 8;
      },
      getChangePercentage: (timePeriod, repository) => {
        return -22;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
    {
      title: "Active Opportunities",
      getValue: (timePeriod, repository) => {
        return 3;
      },
      getChangePercentage: (timePeriod, repository) => {
        return -5;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
    {
      title: "Active Automations",
      getValue: (timePeriod, repository) => {
        return 12;
      },
      getChangePercentage: (timePeriod, repository) => {
        return 7;
      },
      isPercentage: false,
      isTimeSeries: false,
    },
  ];
