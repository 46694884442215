import React from 'react';
import { NavArrowRight, Compass, DashboardSpeed, Activity } from 'iconoir-react';
import { useNavigate } from 'react-router-dom';

interface SuccessMeasureCardProps {
  title: string;
  description: string;
  type: 'direction' | 'speed' | 'health';
  needsAttention: number;
  activeAutomations: number;
}

const SuccessMeasureCard: React.FC<SuccessMeasureCardProps> = ({
  title,
  description,
  type,
  needsAttention,
  activeAutomations,
}) => {
  const navigate = useNavigate();

  const renderIcon = () => {
    switch (type) {
      case 'direction':
        return <Compass className="w-6 h-6" />;
      case 'speed':
        return <DashboardSpeed className="w-6 h-6" />;
      case 'health':
        return <Activity className="w-6 h-6" />;
      default:
        return null;
    }
  };

  const getCategoryParam = (type: string): string => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const handleClick = () => {
    const category = getCategoryParam(type);
    navigate(`/signals?category=${category}`);
  };

  return (
    <div
      className="flex-1 p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold flex items-center">
          {renderIcon()}
          <span className="ml-2">{title}</span>
        </h3>
        <NavArrowRight className="w-5 h-5 text-gray-400" />
      </div>
      <p className="text-xs text-gray-400 mb-4">{description}</p>
      <div className="flex justify-between">
        <div>
          <p className="text-sm text-gray-500">Needs attention</p>
          <p className="text-2xl font-bold">{needsAttention}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Active automations</p>
          <p className="text-2xl font-bold">{activeAutomations}</p>
        </div>
      </div>
    </div>
  );
};

export { SuccessMeasureCard };
