import React, { useMemo } from 'react';
import { InfoCircle, ArrowUp, ArrowDown } from 'iconoir-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { TimeSeriesDataPoint } from '../sample_data/metrics';

interface MetricCardProps {
  title: string;
  getValue: (timePeriod: string, repository: string) => number | TimeSeriesDataPoint[];
  getChangePercentage: (timePeriod: string, repository: string) => number;
  timePeriod: string;
  repository: string;
  isPercentage?: boolean;
  isTimeSeries?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  getValue,
  getChangePercentage,
  timePeriod,
  repository,
  isPercentage = false,
  isTimeSeries = false
}) => {
  const value = useMemo(() => getValue(timePeriod, repository), [getValue, timePeriod, repository]);
  const changePercentage = useMemo(() => getChangePercentage(timePeriod, repository), [getChangePercentage, timePeriod, repository]);

  const formattedValue = isTimeSeries
    ? (value as TimeSeriesDataPoint[]).slice(-1)[0].value
    : isPercentage
      ? (value as number * 100).toFixed(1)
      : (value as number).toLocaleString();

  const displayValue = isPercentage ? `${formattedValue}%` : formattedValue;

  const isPositive = changePercentage >= 0;

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 h-40 flex flex-col">
      <h3 className="text-sm font-medium text-gray-500 mb-2">{title}</h3>
      <div className="flex-grow flex flex-col justify-center overflow-hidden">
        {isTimeSeries ? (
          <div className="h-full w-full">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={value as TimeSeriesDataPoint[]} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <XAxis dataKey="date" tick={false} hide />
                <YAxis hide />
                <Tooltip
                  contentStyle={{ background: 'white', border: '1px solid #ccc' }}
                  labelStyle={{ fontWeight: 'bold' }}
                />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <>
            <p className="text-3xl font-bold mb-1">{displayValue}</p>
            <div className="flex items-center text-xs">
              <InfoCircle className="w-3 h-3 text-gray-400 mr-1" />
              <span className={`font-medium ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
                {isPositive ? '+' : '-'}{Math.abs(changePercentage).toFixed(1)}%
              </span>
              <span className="text-gray-500 ml-1">from previous period</span>
              {isPositive ? (
                <ArrowUp className="w-3 h-3 text-green-600 ml-1" />
              ) : (
                <ArrowDown className="w-3 h-3 text-red-600 ml-1" />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};


export { MetricCard };
