// repository.ts
export type Repository = {
    name: string;
  };

export const repositories: Repository[] = [
    {name: 'langchain-ai/langchain'},
    {name: 'langchain-ai/langServe'},
    {name: 'langchain-ai/langSmith'},
    {name: 'langchain-ai/langgraph'}
]
