import React, { useState, useMemo } from 'react';
import RepositorySelector from '../components/RepositorySelector';
import TimePeriodSelector from '../components/TimePeriodSelector';
import { repositories } from '../sample_data/repository';
import { MetricCard } from '../components/MetricCard';
import { healthMetrics } from '../sample_data/metrics';
import { velocityMetrics } from '../sample_data/metrics';
import { SuccessMeasureCard } from '../components/SuccessMeasureCard';
import { successMeasures } from '../sample_data/successMeasures';
import Toggle from '../components/Toggle';
import TaskDistributionTable from '../components/TaskDistributionTable';

interface TimePeriod {
  label: string;
  value: string;
  dateRange: string;
}

const InsightsPage: React.FC = () => {
    const [selectedRepo, setSelectedRepo] = useState<string>('All repositories');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod | null>(null);
    const [isAutoPilotMode, setIsAutoPilotMode] = useState(false);

    const uniqueRepos = useMemo(() => {
        const repoSet = new Set(repositories.map(repo => repo.name));
        return ['All repositories', ...Array.from(repoSet)].sort();
    }, []);

    const filteredRepositories = useMemo(() => {
        if (selectedRepo === 'All repositories') {
            return repositories;
        }
        return repositories.filter(repo => repo.name === selectedRepo);
    }, [selectedRepo]);

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold">Insights</h1>
                <div className="flex space-x-4">
                    <Toggle
                        label="Auto-pilot mode"
                        isOn={isAutoPilotMode}
                        onToggle={() => setIsAutoPilotMode(!isAutoPilotMode)}
                    />
                    <TimePeriodSelector onSelect={setSelectedTimePeriod} />
                    <RepositorySelector
                        selectedRepo={selectedRepo}
                        onSelectRepo={setSelectedRepo}
                        repositories={uniqueRepos}
                    />
                </div>
            </div>
            <div className="mt-8">
                {/* <h2 className="text-xl font-semibold mb-4">Engineering Success Measures</h2> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {successMeasures.map((measure, index) => (
                        <SuccessMeasureCard
                            key={index}
                            title={measure.title}
                            description={measure.description}
                            type={measure.type as 'direction' | 'speed' | 'health'}
                            needsAttention={measure.needsAttention}
                            activeAutomations={measure.activeAutomations}
                        />
                        ))}
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Team Velocity</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {velocityMetrics.map((metric, index) => (
                        <MetricCard
                            key={index}
                            {...metric}
                            timePeriod={selectedTimePeriod?.value || '7d'} // Default to '7d' if no period is selected
                            repository={selectedRepo}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
                <TaskDistributionTable/>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Organisational Health</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {healthMetrics.map((metric, index) => (
                        <MetricCard
                            key={index}
                            {...metric}
                            timePeriod={selectedTimePeriod?.value || '7d'} // Default to '7d' if no period is selected
                            repository={selectedRepo}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InsightsPage;
